<template>
    <div>
        <function-entry></function-entry> 
        <panel-group
              group-uid="db04ad2d"
              :show-title="false"
        ></panel-group>

    </div>
</template>
<script>
import FunctionEntry from "@/views/front/cabr/dashboard/components/FunctionEntry.vue";
import PanelGroup from "@/views/panel-group";

export default{
    name: "alarm",
    components:{
        FunctionEntry,
        PanelGroup,
    },
    setup(){

    }
}
</script>
<style lang="scss" scoped></style>